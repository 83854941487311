<template>
	<div class="content">
		<Header :title="$t('lang.DJC_title')"></Header>
		<div class="make">
			<div class="text">
				<p>{{ $t('lang.DJC_p1') }}</p>
				<p>{{ $t('lang.DJC_p2') }}</p>
				<p>{{ $t('lang.DJC_p3') }}</p>
				<p>{{ $t('lang.DJC_p4') }}</p>
				<p>{{ $t('lang.DJC_p5') }}</p>
				<table border="1">
					<tr>
						<td>{{ $t('lang.DJC_table1') }}</td>
						<td>{{ $t('lang.DJC_table2') }}</td>
					</tr>
					<tr>
						<td>{{ $t('lang.DJC_table3') }}</td>
						<td>{{ $t('lang.DJC_table4') }}</td>
					</tr>
					<tr>
						<td>{{ $t('lang.DJC_table5') }}</td>
						<td>{{ $t('lang.DJC_table6') }}</td>
					</tr>
					<tr>
						<td>{{ $t('lang.DJC_table7') }}</td>
						<td>{{ $t('lang.DJC_table8') }}</td>
					</tr>
					<tr>
						<td>{{ $t('lang.DJC_table9') }}</td>
						<td>{{ $t('lang.DJC_table10') }}</td>
					</tr>
					<tr>
						<td>{{ $t('lang.DJC_table11') }}</td>
						<td>{{ $t('lang.DJC_table12') }}</td>
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
	import Header from "@/components/Header";
	import HB from "@/assets/js/bridge";
	export default {
		components: {
			Header
		},
		data() {
			return {
				language: '',
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			localStorage.setItem('lang', this.language);
			this.$i18n.locale = localStorage.getItem("lang");
		},
		mounted() {
			
		},
		methods: {
			
		}
	}
</script>

<style lang="scss" scoped>
	// * {
	// 	font-family: PingFang;
	// }
	.content {
		width: 100%;
		height: 3rem;
		background-color: #FFFFFF;
		padding-top: 0.2rem;
	}
	.make {
		width: 92%;
		margin: 0 auto;
		border-radius: 27px;
	}
	.text {
		width: 100%;
		margin: 0 auto;
		padding-top: 0.3rem;
		padding-bottom: 0.25rem;
		p {
			font-size: 0.28rem;
			line-height: 0.4rem;
			font-weight: 400;
			color: #999999;
			margin-bottom: 0.08rem;
		}
		table {
			width: 100%;
			font-size: 0.28rem;
			color: #999999;
			border: 1px solid #999999;
			border-collapse: collapse ;
			border-spacing: 0 ;
			margin-top: 0.4rem;
			tr {
				height: 0.88rem;
				td {
					width: 50%;
					text-align: center;
					border-radius: 1px dotted #999999;
				}
			}
		}
	}
</style>